















import Vue from "vue";

export default Vue.extend({
  name: "FnsRefreshButton",
  data() {
    return {
      showTooltipButton: false,
    }
  },
})
