export enum InitialApplicationStatuses {
  // Отправка документов
  DocumentsSending = 1,

  // Генерация запроса
  CertificateRequest = 2,

  // Выпуск
  CertificateIssue = 3,

  // Готово
  CertificateReady = 4,

  // "Отказ"
  Rejection = 5,

  // Ожидание подписи Главного менеджера
  HeadManagerSign = 11,

  // На модерации
  Moderation= 21,
 
  // Обработка системы
  SystemProcessing = 22
}
